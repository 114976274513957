.guessContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

body {
  background-color: transparent !important;
}
.guessInput {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 31.25rem;
  margin-top: 2.188rem;
}
.guessButton {
  width: 15.5rem;
  background: #f1bf00;
  border-radius: 12px;
  border: none;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
}

.front {
  display: block;
  padding: 12px 42px;
  border-radius: 12px;
  font-size: 1.25rem;
  background: #aa151b;
  color: white;
  transform: translateY(-6px);
}
.guessButton:active .front {
  transform: translateY(-2px);
}

.ButtonBox {
  padding: 1.25rem;
  margin-left: 10px;
}

.innerGuessContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  border-radius: 20%;
  border-style: double;
  border-color: #ffea7f;
  background-color: whitesmoke;
  -moz-box-shadow: inset 0 0 10px #000000;
  -webkit-box-shadow: inset 0 0 10px #000000;
  box-shadow: -5px -5px 30px -10px #aa151b, 5px 5px 40px 0px #f1bf00;
  border-width: 0.625rem;
  height: 43.75em;

  width: 37.5em;
}

img {
  opacity: 0;
}

.spanishWord {
  font-size: 6.25rem;
  align-self: center;
  text-align: center;
  margin-top: 5vh;
}

.spanishWordLarge {
  font-size: 4.375rem;
  align-self: center;
  text-align: center;
  margin-top: 5vh;
}

.score {
  align-self: center;

  padding-bottom: 0;
}
.wordAndForm {
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.btn-xxl {
  font-size: 1.8rem;
}
.textInput {
  text-align: center;
  width: 25rem;
  padding: 0.625rem;
  margin-bottom: 1.875rem;
}
.switch {
  align-self: center;
  width: 9.063rem;
  height: 5rem;
  background-color: whitesmoke;
  display: flex;
  justify-content: flex-start;
  border-radius: 3.125rem;
  padding: 0.625rem;
  cursor: pointer;
  margin-bottom: 1.875rem;
}

.switch[data-isOn='true'] {
  justify-content: flex-end;
  background-color: #0fa;
}

.handle {
  width: 3.75rem;
  height: 3.75rem;
  background-color: grey;
  border-radius: 2.5rem;
}
.modeSelectContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.modeTitle {
  padding: 1.563rem;
  font-size: 2.125rem;
  color: white;
}
.selectedMode {
  padding: 1.563rem;
  font-size: 3.125rem;
  color: #ffffff;

  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
@media only screen and (max-device-width: 580px) {
  .guessContainer {
    margin-top: 0.1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }

  .innerGuessContainer {
    border-width: 0.625rem;
    width: 21rem;
    height: 33rem;
  }
  .guessInput {
    width: 20.25rem;

    margin-bottom: 1rem;

    width: 15rem;
  }
  .guessButton {
    scale: 80%;
  }
  .ButtonBox {
    padding: 0.4rem;
  }

  img {
    opacity: 0;
  }

  .spanishWord {
    font-size: 4.25rem;
    align-self: center;
    text-align: center;
    margin-top: 5vh;
  }

  .spanishWordLarge {
    font-size: 3.375rem;
    align-self: center;
    text-align: center;
    margin-top: 5vh;
  }

  .score {
    align-self: center;

    padding-bottom: 0;
  }
  .form-group {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
  }

  .textInput {
    margin-top: 1.5rem;
  }
  .wordAndForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .btn-xxl {
    font-size: 1.8rem;
  }
  .switch {
    width: 9.063rem;
    height: 5rem;
    border-radius: 3.125rem;
    cursor: pointer;
  }
  .modeSelectContainer {
    margin-bottom: -1rem;
  }

  .handle {
    width: 3.75rem;
    height: 3.75rem;
    background-color: grey;
    border-radius: 2.5rem;
  }

  .modeTitle {
    padding: 1.563rem;
    font-size: 2.125rem;
  }
  .selectedMode {
    padding: 1.563rem;
    font-size: 3.125rem;
  }
  .modeSelectContainer {
    scale: 75%;
  }
}
