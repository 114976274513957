html {
  background: url(sbg.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

img {
  opacity: 0;
}
body {
  background-color: transparent;
}

.homeContainer {
  margin-top: 8rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.homeBox {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  border-radius: 20%;
  border-style: double;
  border-color: #ffea7f;
  background-color: whitesmoke;
  -moz-box-shadow: inset 0 0 10px #000000;
  -webkit-box-shadow: inset 0 0 10px #000000;
  box-shadow: -5px -5px 30px -10px #aa151b, 5px 5px 40px 0px #f1bf00;
  border-width: 0.625rem;
  height: 43.75em;

  width: 37.5em;
}

p {
  font-size: 2.188rem;
  font-weight: bold;
  color: black;
}
.instructions {
  align-self: center;
  margin-top: 3rem;
  width: 31.25rem;
  font-size: 1.063rem;
  font-family: sans-serif;
}

.startButton {
  margin-top: 3rem;
  width: 12.5rem;
  background: #f1bf00;
  border-radius: 12px;
  border: none;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
}
.front {
  display: block;
  padding: 12px 42px;
  border-radius: 12px;
  font-size: 1.25rem;
  background: #aa151b;
  color: white;
  transform: translateY(-6px);
}

.titleButtonInstructionsBox {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20%;
}
@media only screen and (max-device-width: 580px) {
  .homeContainer {
    margin-top: 0.1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }

  .homeBox {
    border-width: 0.625rem;
    width: 21rem;
    height: 33rem;
  }
  .guessInput {
    width: 20.25rem;

    margin-bottom: 1rem;

    width: 15rem;
  }
  .startButton {
    scale: 80%;
    margin-top: 0rem;
  }
  .ButtonBox {
    padding: 0.4rem;
  }

  img {
    opacity: 0;
  }

  .btn-xxl {
    font-size: 1.8rem;
  }
  .instructions {
    margin-top: 8%;
    width: 15.25rem;
    font-size: 1rem;
  }
  p {
    font-size: 2.5rem;
    text-align: center;
  }
  .titleButtonInstructionsBox {
    display: flex;
    align-items: center;
    align-content: center;
  }
}
